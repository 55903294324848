import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";

import downArrow from "../images/down-arrow.svg";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from "swiper";
import ScrollAnimation from "react-animate-on-scroll";
import { Animated } from "react-animated-css";
import PopupForm from "../components/popupForm";

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);
var accItem;
class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
		};
	}

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

	componentDidMount() {
		accItem = document.getElementsByClassName("accordionItem");
		let accHD = document.getElementsByClassName("accordionItemHeading");
		for (let i = 0; i < accHD.length; i++) {
			accHD[i].addEventListener("click", this.toggleItem, false);
		}
	}
	accorfn = () => {
		accItem = document.getElementsByClassName("accordionItem");
		let accHD = document.getElementsByClassName("accordionItemHeading");
		for (let i = 0; i < accHD.length; i++) {
			accHD[i].addEventListener("click", this.toggleItem, false);
		}
	};
	toggleItem() {
		var itemClass = this.parentNode.className;
		for (let i = 0; i < accItem.length; i++) {
			accItem[i].className = "accordionItem close";
		}
		if (itemClass == "accordionItem close") {
			this.parentNode.className = "accordionItem open";
		}
	}

	render() {
		const post = this.props.data.allWordpressPage.edges[0].node;
		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 
	

		return (
			<div>
				<PopupForm time={this.state.time} />
				<Layout>
					<Helmet>
					<title>{metatitle}</title>
          			<meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
		  {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
        
          
		 </Helmet>

		 <div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item active"> {post.title}</li>
										</ol>
				</div>
				</div>


					<div className="container">
						
					</div>
					<section class="countrie-banner-sec" id="faq-mob-fwq">
			
						<div class="container">
							<div class="row">
								<div class="col-12">
									<div class="countrie-banner-inner faq">
										<ScrollAnimation animateIn="fadeIn" style={{ "animation-duration": "1s" }}>
											{" "}
											<div class="text-box wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
												<h1>{post.title}</h1>
												
											</div>{" "}
										</ScrollAnimation>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section class="faq-sec">
						<Animated animationIn="fadeInUp" animationOut="fadeInDown" isVisible={true}>
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="faq-inner wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
											<div class="accordionWrapper">
												

												{post.acf.faq_item.map((item, index) => {
													return (
														<div class="accordionItem close" onclick={() => this.accorfn()}>
															<h2 class="accordionItemHeading">
																{item.faq_title}
																<span>
																	<img src={downArrow} alt="Arrow" />
																</span>
															</h2>
															<div class="accordionItemContent">
																<div dangerouslySetInnerHTML={{ __html: item.faq_content }} />
															</div>
														</div>
													);
												})}
											</div>
											<div class="enquire-btn">
												
												<a class="btn btn-default" onClick={() => this.handlePopupWindow()}>
													SPEAK WITH US
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Animated>
					</section>
				</Layout>
			</div>
		);
	}
}
export default Faq;

export const WorldgradFaqQuery = graphql`
	query WorldgradFaqQuery {
		allWordpressPage(filter: { wordpress_id: { in: 2746 } }) {
			edges {
				node {
					id
					title
					slug
					acf {
						faq_item {
							faq_content
							faq_title
						}
						faq_button_label
						meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
					}
				}
			}
		}
	}
`;
